@import url('https://fonts.googleapis.com/css?family=Patrick+Hand&effect=shadow-multiple');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300');
body{
  background-color: #fce2ca;
}
.App{
  margin: auto;
  padding: 10px;
  background-color: #fce2ca;
  text-align: center;
}

p{
  font-family: 'Patrick Hand';
}
.gambar{
  height: 200px;
  width: 200px;
}
.num{
  font-weight: bold;
  font-size: xx-large;
}

.btnz{
  line-height: 30px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  font-family : 'Open Sans Condensed';
  font-size: large;
  border: #282c34 solid 0.5px;
  text-decoration: none;
  color: black !important;
}

.btnz:hover{
  background-color: #FFB77D;
}

.txtTitle{
  font-family : 'Open Sans Condensed';
  font-weight: 700;
}