.tblCenter{
    text-align: center;
}
.tblContent{
    height: 150px;
    border: 1px solid;
}
.txtDate{
    text-align: start;
    font-weight: lighter;
    color: black;
}
.tblHeader{
    height: 50px;
    border: 1px solid;
}
.txtHeader{
    font-size: larger;
    line-height: 40px;
}
.calendarTable{
    color: white !important;
    margin-top: 20px;
    overflow-x: hidden;
}
.txtInput{
    background-color: #ffe1c9;
    font-family: 'Open Sans Condensed';

}