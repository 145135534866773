@import url('https://fonts.googleapis.com/css?family=Patrick+Hand&effect=shadow-multiple');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300');

form {
  margin: 0 auto;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 1px 2px 10px rgb(0, 0, 0);
}
form h3 {
  text-align: center;
}
form input {
  width: 100%;
  padding: 10px 5px;
  outline: none;
  font-size: 16px;
  font-family: "Patrick Hand";
  font-weight: 500;
}
.input_container {
  margin-bottom: 20px;
}
.btn_container {
  text-align: center;
  margin-bottom: 10px;
}
.btn_container button {
  background: #efefef;
  padding: 10px 20px;
  border: 1px solid;
  font-size: 16px;
  cursor: pointer;
  font-family: "Open Sans Condensed";
  font-weight: 500;
}
.btn_container button:hover{
  background-color: #FFB77D;
}

.todo_container {
  margin-bottom: 10px;
}
.todo {
  margin-top: 20px;
  background: #efefef;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 2px 10px #ddd;
}
.todo input {
  cursor: pointer;
  width: 60%;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  font-family: "Patrick Hand";
  font-weight: 500;
}
.button-complete,
.button-delete,
.button-edit {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
}
#i {
  font-size: 25px;
}
.button-complete {
  color: #95faa3;
}
.button-edit {
  color: #56cfff;}
.button-delete {
  color: rgb(255, 122, 98);
}
@media screen and (max-width: 667px) {
  .form_container{
    margin: 10px;
  }
  .todo_container{
    margin: 10px;
  }
  .task_container {
    width: 80%;
  }
  .task {
    margin-right: 0px;
  }
}
input:focus,
button:focus {
  outline: none;
}