@import url('https://fonts.googleapis.com/css?family=Patrick+Hand&effect=shadow-multiple');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300');

.homepage{
  overflow-x: hidden;
}
p{
  font-family: 'Patrick Hand';
}
.gambar{
  height: 200px;
  width: 200px;
}
.num{
  font-weight: bold;
  font-size: xx-large;
}

.btnz{
  line-height: 30px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  font-family : 'Open Sans Condensed';
  font-size: x-large;
  border: #282c34 solid 0.5px;
  text-decoration: none;
  color: black !important;
}

.btnz:hover{
  background-color: #FFB77D;
}

.txtTitle{
  font-family : 'Open Sans Condensed';
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  text-decoration: underline;
}

.icon{
  height: 80px;
  width: 80px;
}

.icon:hover{
  transition: all 0.3s ease;
  height: 85px;
  width: 85px;
}

.hadithDesc{
  font-weight: bold;
}

.hadithRiwayah{
  font-weight: bold;
  font-style: italic;
  text-align: center;
}

.ctrContainer{
  text-align: center;
  background-color: #fce2ca;
}

.dailyHadithContainer{
  margin-right: 100px;
}
.hadithContainer{
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 1px 2px 10px rgb(0, 0, 0);
}

@media screen and (max-width: 667px) {
  .dailyHadithContainer{
    margin: 10px;
  }
}